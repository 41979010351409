import { ProgramDTO } from '@/data/dto/program.dto';
import Mapper from '@/data/mapper';
import { Program } from '@/data/entity/program.entity';

export default class ProgramsMapper extends Mapper<Program[], ProgramDTO[]> {
  toDTO(programs: Program[]): ProgramDTO[] {
    const ret = programs.reduce<{ [key: string]: ProgramDTO }>((
      previousValue,
      currentValue,
    ) => {
      if (previousValue[currentValue.program] !== undefined) {
        previousValue[currentValue.program].images.push({
          uuid: '',
          marketId: currentValue.marketId,
          url: currentValue.url,
        });
        // eslint-disable-next-line no-param-reassign
        previousValue[currentValue.program].updatedAt = currentValue.updated_at || '';
      } else {
        // eslint-disable-next-line no-param-reassign
        previousValue[currentValue.program] = {
          key: currentValue.program,
          name: currentValue.title,
          images: [{
            marketId: currentValue.marketId,
            url: currentValue.url,
            uuid: currentValue.id,
          }],
          createdAt: currentValue.createdAt,
          updatedAt: currentValue.updated_at,
        };
      }
      return previousValue;
    }, {});

    return Object.entries(ret).map<ProgramDTO>((obj) => obj[1]);
  }

  toDomain(o: ProgramDTO[]): Program[] {
    return [];
  }
}
